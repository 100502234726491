import React, { useEffect, useState } from "react"
import PreHeader from "../components/preHeader"
import HeaderDrak from "../components/headerDrak"
import Footer from "../components/footer"
import { Container, Row, Col } from "react-bootstrap"
import backBtn from "../images/arrow-grren-outline.svg"
import "../styles/main.scss"

// import for translate
import {
  AUTH_TOKEN_KEY,
  COLOR_GREEN,
  COLOR_RED,
  DOMAIN_API,
  TYPE_ACTU,
  TYPE_ADVANTAGE,
  TYPE_ALL,
  URL_GET_USER_FULL,
  URL_GETALL_ADVANTAGES,
  URL_GETALL_ADVICEES,
  URL_GETALL_FOLDERS,
  URL_GETALL_NEWS,
  URL_GETALL_VIDEOS,
  URL_GETALL,
  URL_IS_AUTH,
  translate,
  URL_GET_MAINPARTNERSUPER_DATA,
  URL_GETALL_PARTNER_SUPER_FILTERED,
  getUserLanguage,
  KEY_LANGUAGE_USER, KEY_LANGUAGE_BROWSER, FR, NL
} from "../utils"
import gifFR from "../images/screen-fr.gif"
import gifNL from "../images/screen-nl.gif"
import TagManager from "react-gtm-module"

const PrivacyTerms = () => {

  useEffect(() => {
    const userLanguage = getUserLanguage()
    if (userLanguage === NL) window.location.href = '/privacy-terms-nl'
  },[])

  return (
    <div>
      <PreHeader />
      <div className="privacyTerms">
        <HeaderDrak />
        <div className="pageContent">
          <Container>
            <Row className="justify-content-center align-items-lg-end">
              <Col lg="8" className="order-lg-2">
                <h1 className="h0-title yellow-privacy">
                <span className="d-block">
                  Politique de confidentialité</span>
                </h1>

                {/* paragraphe one start */}
                <h2 className="h2-title yellow-privacy">1. Généralités</h2>
                <p className="yellow-privacy">
                  Nous estimons que la protection de la vie privée revêt une importance cruciale.
                </p>
                <p className="yellow-privacy">
                  Nous souhaitons vous informer autant que possible, vous fournir des informations concernant les données que nous collectons, les finalités que nous poursuivons de la sorte, la durée de conservation, les droits dont vous disposez en matière de respect de la vie privée et la manière dont vous pouvez les exercer.
                </p>
                {/* paragraphe one end */}

                {/* paragraphe two start */}
                <h2 className="h2-title  yellow-privacy">2. Responsable du traitement</h2>
                <p className="yellow-privacy">
                  IN TEMPORE srl (agissant sous le nom commercial de ENTER-BUSINESS), dont le siège social est établi avenue Kersbeek 306 1180 Bruxelles et inscrite à la BCE sous le numéro BE 0477.292.452 (ci-après « ENTER-BUSINESS »), est le responsable du traitement de vos données à caractère personnel.
                </p>
                <p className="yellow-privacy">Cela signifie qu’ ENTER-BUSINESS définit les finalités et les moyens du traitement de vos données à caractère personnel.</p>
                {/* paragraphe two end */}

                {/* paragraphe three start */}
                <h2 className="h2-title yellow-privacy">3. Quels types de données possédons-nous sur vous ?</h2>
                <p className="yellow-privacy">
                  ENTER-BUSINESS ne dispose pas des mêmes données pour toutes les personnes. Cela dépendra des données que vous ou nos partenaires nous ont communiquées. Vous trouverez donc ici une énumération des données dont ENTER-BUSINESS pourrait disposer :
                </p>
                <h4 className="h2-title yellow-privacy">LES DONNÉES À CARACTÈRE PERSONNEL QUE VOUS PARTAGEZ AVEC NOUS</h4>
                <p className="yellow-privacy">Nous traitons les données à caractère personnel que vous nous communiquez vous-même spontanément. Cette communication peut se faire par téléphone (par exemple lorsque vous appelez le Service Clientèle pour poser une question), par écrit (par exemple lorsque vous nous envoyer un courrier postal), par voie électronique (par exemple lorsque vous complétez votre formulaire d‘inscription sur les sites ENTER-BUSINESS.COM) ou que vous nous envoyez un SMS ou un e-mail, que vous vous inscrivez à un concours en ligne ou téléchargez une app ENTER-BUSINESS.</p>
                <p className="yellow-privacy"><span className="yellow-privacy">Il s’agit notamment des données suivantes :</span></p>
                <ul className="yellow-privacy">
                  <li>Nom</li>
                  <li>Prénom</li>
                  <li>Sexe</li>
                  <li>Langue</li>
                  <li>Âge (ou date de naissance, ou âge moyen supposé)</li>
                  <li>Adresse postale</li>
                  <li>Numéro de téléphone</li>
                  <li>Numéro de GSM</li>
                  <li>Adresse e-mail</li>
                  <li>Nom de société</li>
                  <li>Adresse postale de votre société</li>
                  <li>Type d’activité de votre société</li>
                  <li>Fonction occupée</li>
                  <li>Centres d’intérêt</li>
                </ul>


                <h4 className="h2-title yellow-privacy">LES DONNÉES À CARACTÈRE PERSONNEL OBTENUES DE TIERS</h4>
                <p className="yellow-privacy">Nous pouvons recevoir vos données depuis des entreprises actives dans le secteur des entreprises ou de l’entreprenariat ou des d'entreprises de big data spécialisées dans des données sociodémographiques et des informations sur vous-mêmes pour adapter encore mieux nos services à vos besoins.</p>
                <p className="yellow-privacy">Ensuite, nous pouvons recevoir de l'ASBL DNCM des récapitulatifs périodiques des personnes qui figurent sur la liste 'Ne m'appelez plus'</p>
                <p className="yellow-privacy">L’entreprise qui nous fournit vos données peut également nous communiquer la date à laquelle vous avez eu le dernier contact avec elle (parce que vous avez acheté un produit ou un service ou parce que vous avez participé à un concours par exemple). Cette information nous permet de vérifier que vos données sont correctes et à jour (si vous venez de recevoir à la maison votre journal, la probabilité que vous habitez bien à cette adresse sera élevée).</p>

                <h4 className="h2-title yellow-privacy">LES DONNÉES À CARACTÈRE PERSONNEL OBTENUES À PARTIR D’ENQUÊTES</h4>
                <p className="yellow-privacy">Si vous remplissez nos questionnaires de consommation (ou ceux de nos partenaires), nous disposerons de données supplémentaires à votre sujet, en fonction des réponses que vous avez données.</p>

                <h4 className="h2-title yellow-privacy">LES DONNÉES DE LOCALISATION OBTENUES À PARTIR DES LIEUX FRÉQUENTÉS</h4>
                <p className="yellow-privacy">Dans certains cas, nous pouvons identifier les commerces que vous visitez fréquemment tels que les bureaux, les salons ou salles de réunion. La récolte de ces données nous sert à évaluer correctement la qualité de notre réseau de distribution des cartes ENTER-BUSINESS.</p>

                <h4 className="h2-title yellow-privacy">AUTRES</h4>
                <p className="yellow-privacy">Cookies ou autre technique similaire : Lorsque vous visitez nos sites web <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                  nous pouvons collecter des informations provenant de nos cookies.</p>
                <p className="yellow-privacy">Nous pouvons également placer des cookies sur les emails que nous vous envoyons régulièrement conformément à nos engagements. Ces informations collectées nous aident à vous proposer des produits ou services spécifiques qui pourraient vous intéresser et mesurer votre intérêt sur le contenu des emails envoyés.</p>
                <p className="yellow-privacy">Dans la mesure du possible, nous conservons également les dates auxquelles toutes ces informations nous sont communiquées ou auxquelles des changements leur ont été apportés.</p>
                {/* paragraphe three ends */}

                {/* paragraphe four starts */}
                <h2 className="h2-title yellow-privacy">4. Comment collectons-nous les données ?</h2>
                <p className="yellow-privacy">
                  Voici un aperçu des questions les plus fréquemment posées.</p>

                <h4 className="h2-title yellow-privacy">COMMENT ENTER-BUSINESS COLLECTE DES DONNÉES SUR VOUS ?</h4>
                <p className="yellow-privacy">ENTER-BUSINESS collecte des données auprès de vous par le biais de différents moyens auxquels vous pouvez vous inscrire volontairement pour recevoir des réductions, des échantillons, des guides d’information, participer à des concours ou profiter de promotions.</p>
                <p className="yellow-privacy">Cette inscription peut se faire par téléphone (par exemple lorsque vous appelez le Service Clientèle pour poser une question), par écrit (par exemple lorsque vous nous envoyer un courrier postal), par voie électronique (par exemple lorsque vous complétez votre formulaire d‘inscription sur les sites ENTER-BUSINESS.COM) ou que vous nous envoyez un SMS ou un courriel, que vous vous inscrivez à un concours en ligne ou téléchargez une app ENTER-BUSINESS.</p>
                <p className="yellow-privacy">ENTER-BUSINESS a également conclu des partenariats avec des entreprises commerciales qui lui communiquent les données de leurs clients.</p>
                <p className="yellow-privacy">Les données peuvent également être complétées avec un certain nombre d’informations statistiques, telles que celles fournies par l’Institut national de Statistiques comme par exemple, le revenu moyen du quartier où habitent les personnes, le pourcentage de jardins, de propriétaires, …</p>
                <p className="yellow-privacy">L’ensemble de ces données est rassemblé pour former notre fichier « consommateurs » qui est commercialisé.</p>

                <h4 className="h2-title yellow-privacy">POUVONS-NOUS COMPLÉTER VOS DONNÉES AVEC DES DONNÉES QUE NOUS DÉDUISONS OU QUE NOUS SUPPOSONS ?</h4>
                <p className="yellow-privacy">Oui nous pouvons compléter vos données avec des données déduites ou supposées. Ainsi si une entreprise est basée à une adresse et qu’à la même adresse, nous avons les données d’une autre entreprise, nous en déduirons qu’elle fait partie du même immeuble que vous. Cela ne veut pas dire que nous supposons un quelconque lien entre vous. En réalité cette information est intéressante d’un point de vue marketing .</p>

                <h4 className="h2-title yellow-privacy">ÉTABLISSONS-NOUS DES PROFILS SUR ENTER-BUSINESS DES DONNÉES QUE NOUS COLLECTONS ?</h4>
                <p className="yellow-privacy">
                  Oui nous pouvons établir des profils marketing. Concrètement, cela veut dire que nous pouvons vous placer dans des « segments marketing », par exemple « dirigeant d’une société dans l’Horeca », « entrepreneur ayant plusieurs sociétés », « société située dans la commune X » …
                </p>
                <p className="yellow-privacy">
                  Cette catégorisation aura pour conséquence que nous commercialiserons vos données auprès d’entreprises qui vendent des produits ou services que nous estimons plus susceptibles de vous intéresser. A l’inverse, elle permet aussi que les entreprises qui vendent des produits ou services qui ne vous intéresseraient probablement pas évitent de vous contacter pour de la publicité.
                </p>
                <p className="yellow-privacy">Les critères peuvent être purement géographiques, ainsi une entreprise nous demandera une sélection de personnes habitant dans un rayon de 10km de son magasin ou habitant dans la commune X. De manière plus large, si nous supposons que vous êtes société active dans l’Horeca, vous recevrez davantage de publicités pour du matériel de cuisine, la gestion de salles de restauration, l’aménagement de la cuisine, … . Si vous êtes une société active dans le milieu de la construction, vous recevrez plutôt des publicités sur le secteur immobilier, des engins et matériaux de construction.</p>
                <p className="yellow-privacy">Ces profils ne sont pas préétablis, nous pouvons les construire en fonction des demandes de nos clients et nous ne les conservons pas nécessairement.</p>

                <h4 className="h2-title yellow-privacy">CES PROFILS PEUVENT-ILS AVOIR UN EFFET JURIDIQUE SUR VOUS OU VOUS AFFECTER SIGNIFICATIVEMENT ?</h4>
                <p className="yellow-privacy">Non. Nous évaluons simplement ce que nous appelons dans notre jargon votre « potentiel marketing », c’est-à-dire si votre profil fera de vous un client plus intéressé que la moyenne pour les produits ou services d’une entreprise. Ce profil peut juste être un score (par exemple, pour un modèle de voiture « utilitaire », une entreprise de transport un score plus élevé qu’une société de marketing) ou une description de votre profil marketing. La conséquence sera par exemple que vous recevrez sans doute une publicité différente de celle de votre voisin. Cela ne veut pas dire qu’il ne pourra pas lui aussi acquérir ce même produit ou service aux mêmes conditions. Simplement le contenu publicitaire de sa boîte aux lettres ou boîte email sera différent du vôtre.</p>
                <p className="yellow-privacy">Il peut arriver que le score lié à votre profil soit combiné avec d’autres scores construits par nos clients. Dans ce cas, l’interprétation finale de l’ensemble des scores ou profils est de la responsabilité de nos clients. Si nos clients devaient exploiter ces profils combinés d’une façon qui pourrait avoir un effet juridique sur vous ou vous affecter significativement, ils s’engagent à vous en informer et à respecter les dispositions légales applicables.</p>

                <h4 className="h2-title yellow-privacy">D’OÙ VIENNENT LES DONNÉES STATISTIQUES UTILISÉES PAR ENTER-BUSINESS BELGIUM ?</h4>
                <p className="yellow-privacy">Ces données proviennent de l’Institut National de Statistiques, de la BCE ou de toute autre institution qui pourrait les mettre à disposition sur le marché, ce qui est couramment appelé les Open Data. Il s’agit de données anonymes relatives à un quartier ou un groupement d’individus et donc pas relatives à une personne spécifique.</p>
                <p className="yellow-privacy">ENTER-BUSINESS peut également construire ses propres modèles statistiques.</p>
                {/* paragraphe four ends */}

                {/* paragraphe five starts */}
                <h2 className="h2-title yellow-privacy">5. Comment pouvons-nous utiliser vos données ?</h2>

                <h4 className="h2-title yellow-privacy">TRANSMISSION DE VOS DONNÉES</h4>

                <p className="yellow-privacy">Nous utilisons vos données pour les transmettre aux entreprises qui souhaitent vous contacter à des fins publicitaires : par exemple nous sélectionnerons toutes les personnes représentant des sociétés dont le siège est dans la commune X, pour le compte d’un magasin de fourniture de bureaux établi dans la même commune et qui souhaite envoyer son nouveau catalogue.</p>
                <p className="yellow-privacy">Nous les utilisons également pour établir des profils marketing permettant de sélectionner des groupes de personnes que nous pensons être plus intéressées à recevoir tel ou tel type de publicités : par exemple, nous sélectionnerons des personnes représentant des sociétés dont le siège est dans des quartiers où le pourcentage de jardins est élevé pour le compte d’un magasin en ligne de produits de jardinage.</p>
                <p className="yellow-privacy">Lorsque vous nous en donnez explicitement l’autorisation, vos données sont également transmises à des partenaires externes afin de vous contacter pour vous fournir des offres personnalisées - éventuellement basées sur votre profil marketing - ou pour effectuer des études ou analyses de marché. Dans ce contexte, vos données peuvent également être utilisées pour valider, corriger ou relier des données déjà présentes dans les bases de données d'autres entreprises .</p>


                <h4 className="h2-title yellow-privacy">QUELLES SONT LES DIFFÉRENTES CATÉGORIES DE CLIENTS QUI POURRAIENT UTILISER VOS DONNÉES ?</h4>


                <p className="yellow-privacy">Secteur automobile : producteurs, importateurs et concessionnaires de différentes marques de voitures et de sociétés de leasing</p>
                <p className="yellow-privacy">Agences de publicité : Agences qui préparent des campagnes publicitaires pour leurs clients (par exemple, mise en page et envoi d'un mailing, campagne email, ...)</p>

                <p className="yellow-privacy">Biens durables : fabricants de produits à longue durée de vie tels que des appareils électroménagers (machine à laver, cafetière, ...), télévision, des meubles, ... ainsi que le secteur de la construction</p>
                <p className="yellow-privacy">Biens de consommation rapide : producteurs de produits de consommation (aliments, boissons, aliments pour animaux de compagnie, produits de nettoyage, produits de lessive, produits de soins, ...) qui sont principalement vendus dans les supermarchés</p>
                <p className="yellow-privacy">Secteur financier - Banques et assurances : Banques, assurances et établissements de crédit</p>
                <p className="yellow-privacy">Collecte de fonds : Organismes de bienfaisance qui recueillent des fonds pour de bonnes causes</p>
                <p className="yellow-privacy">Santé : Les entreprises du secteur de la santé telles que l'assurance maladie, les producteurs parapharmaceutiques, ...</p>
                <p className="yellow-privacy">Loisirs et temps libre : Expositions, théâtre, cinéma, musées, jouets, ...</p>
                <p className="yellow-privacy">Ordinateur : Logiciels et sociétés informatiques</p>
                <p className="yellow-privacy">Institutions : Fédérations professionnelles, associations de consommateurs, partis politiques, ...</p>
                <p className="yellow-privacy">List Brokers : Intermédiaire pour la commercialisation des données dans tous les secteurs listés</p>
                <p className="yellow-privacy">Loteries : Loterie, casino</p>
                <p className="yellow-privacy">Vente au détail : Chaînes de vente au détail, supermarchés, ...</p>
                <p className="yellow-privacy">Étude de marché : Établissements d'études de marché</p>
                <p className="yellow-privacy">Institutions gouvernementales : Administration, transport, distribution du courrier, ...</p>
                <p className="yellow-privacy">Vente par correspondance : Entreprises offrant des services et des biens via Internet ou du porte-à-porte</p>
                <p className="yellow-privacy">Télécommunication : Téléphonie (mobile et / ou fixe), fournisseurs d'accès à internet et à la télévision</p>
                <p className="yellow-privacy">Tourisme : Agences de voyage, locations de vacances, ...</p>
                <p className="yellow-privacy">Médias et édition : Journaux, magazines et maisons d'édition de livres</p>
                <p className="yellow-privacy">Services publics : Services publics tels que les fournisseurs d'électricité, d'eau et de gaz</p>
                <p className="yellow-privacy">Divers : Toute autre entreprise travaillant au service des consommateurs ou des sociétés.</p>


                <h4 className="h2-title yellow-privacy">UTILISATION DE VOS DONNÉES POUR AMÉLIORER LA QUALITÉ DES DONNÉES SUR LE MARCHÉ
                </h4>

                <p className="yellow-privacy">Nous utilisons vos données à des fins de validation : les entreprises nous demandent de vérifier si les données dont elles disposent sont également présentes dans notre fichier. Cela leur permet de vérifier si leurs adresses sont à jour ou sont bien correctes (si elles doivent vous envoyer un paquet, il vaut mieux que l’adresse soit correctement libellée). Dans ce cas, concrètement, nous ne leur transmettons pas vos données, nous indiquons simplement si la donnée examinée est la même que la nôtre ou pas.</p>

                <p className="yellow-privacy">Nous pouvons également corriger les données des entreprises en les comparant avec les nôtres. Ainsi nous pouvons corriger un code postal qui ne correspond pas à la rue indiquée, nous pouvons également communiquer un déménagement, …  A nouveau, cela permet aux entreprises d’avoir des données à jour pour mieux communiquer avec vous.</p>
                <p className="yellow-privacy">Enfin, nous pouvons enrichir les données des entreprises. Par exemple si l’entreprise en question dispose de votre nom et de votre adresse postale, nous pouvons lui communiquer votre numéro de téléphone ou votre âge, si ces informations sont présentes dans notre base de données. Nous pouvons également lui communiquer des données de profils marketing.</p>

                <p className="yellow-privacy">Ces informations serviront à l’entreprise à mieux vous connaître et à déterminer quel est son type de clients. De la sorte, elle peut adapter sa communication (en utilisant par exemple un langage plus « branché » si elle s’adresse à des clients jeunes).</p>

                <h4 className="h2-title yellow-privacy">UTILISATION DE VOS DONNÉES POUR UN GROUPEMENT DE DONNÉES</h4>

                <p className="yellow-privacy">
                  Il arrive qu’un client dispose de plusieurs données vous concernant mais ne les a pas reliées entre elles, par exemple parce qu’il ne les a pas toutes collectées à la même occasion. Dans ce cas, nous pouvons utiliser nos données pour effectuer cette opération, par exemple si dans notre fichier nous avons les données suivantes : Madame Sophie Dubois, rue du Bois à 1000 Boisvert, tél : 01.123.45.67, s.dubois@skynet.be et que dans le fichier du client nous trouvons d’un côté Madame Dubois et son adresse postale et de l’autre côté sophie.dubois@skynet.be et le numéro de téléphone 01.123.45.67, nous indiquerons au client qu’il s’agit de la même personne.
                </p>

                <h4 className="h2-title yellow-privacy">UTILISATION DE VOS DONNÉES POUR UNE ANALYSE DE DONNÉES</h4>

                <p className="yellow-privacy">
                  Nous avons déjà parlé des profils marketing que nous établissons. Nous pouvons également analyser les données de nos clients au moyen des profils que nous utilisons nous-mêmes. Cela permet aux clients de mieux connaître le type de consommateurs intéressés par leurs produits. Ainsi nous pourrions déterminer que les lecteurs du magazine XY sont plutôt des hommes jeunes, intéressées par le sport et le cinéma. Cela permet ensuite au client de proposer des abonnements avec des tickets de cinéma gratuits par exemple. Son action aura sans doute plus de succès que s’il offre des produits de maquillage.
                </p><p className="yellow-privacy">
                Après avoir analysé les données du client, nous pouvons également lui proposer de sélectionner dans notre base de données des personnes avec le même profil. Dans notre exemple, son action publicitaire de prospection aura sans doute plus de succès que s’il s’adresse à des femmes.
              </p><p className="yellow-privacy">
                Nous pouvons transmettre cette sélection à des réseaux sociaux ou autres médias. Ceux-ci vérifient ensuite s’ils vous retrouvent dans leur propre banque de données et affichent sur votre page la même action publicitaire.
              </p><p className="yellow-privacy">
                nfin, vos données peuvent également servir dans le cadre d’analyses sur des données de masse, toujours dans cette finalité de marketing ou d’études de marché.
              </p>

                <h4 className="h2-title yellow-privacy">UTILISATION DE VOS DONNÉES POUR UN USAGE INTERNE</h4>
                <p className="yellow-privacy">
                  Nous pouvons également traiter vos données pour des usages interne à notre entreprise comme par exemple :
                </p><p className="yellow-privacy">
                Nous pouvons utiliser vos données pour vous informer de la disponibilité de votre carte de membre, vous transmettre une information importante liée à votre entreprise, vous envoyer de la promotion sur nos autres médias ou organiser une enquête afin de mesurer la qualité de nos services ou média.
              </p><p className="yellow-privacy">
                Nous pouvons utiliser vos données pour vous transmettre au nom de ENTER-BUSINESS de l’information provenant d’un tiers et susceptible de vous intéresser en tant que représentant ou membre de l’entreprise que vous êtes.
              </p>

                {/* paragraphe five ends */}


                {/* paragraphe six starts */}
                <h2 className="h2-title yellow-privacy">6. Quelle est la base légale du traitement de vos données ?</h2>

                <p className="yellow-privacy">Sur base de votre autorisation et la base légale de l’article 6a du RGPD : Notamment lorsque vous nous communiquez librement des données sur nos sites <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                  ou tout autre questionnaire que nous ou nos partenaires pouvons éditer) ou lorsque vous vous adressez directement à nous pour une demande spécifique ou lorsque vous consentez librement au traitement de vos données par tout autre canal tel qu’un coupon papier rempli par vos soins.
                </p><p className="yellow-privacy">
                Sur base de la poursuite de notre intérêt légitime, dans le cadre de nos activités de marketing (y compris d’amélioration de la qualité de bases de données de nos clients) et la base légale de l’article 6f du RGPD : Nous avons un intérêt légitime à poursuivre les activités que nous venons de décrire ci-dessus. En effet le RGPD prévoit dans son préambule n°47 que les activités liées au « marketing direct » constituent un intérêt légitime.
              </p><p className="yellow-privacy">Il va de soi que cet intérêt doit être constamment mis en balance avec les droits des personnes enregistrées dans notre fichier. C’est à cette fin que nous veillons à être le plus transparent possible quant à l’utilisation des données et nous respectons scrupuleusement le RGPD quant aux droits de la personne.
              </p><p className="yellow-privacy">Ce fondement légal est utilisé pour tout traitement de données pour lesquelles nous ne demandons pas votre consentement explicite.</p>

                {/* paragraphe six ends */}




                {/* paragraphe seven starts */}
                <h2 className="h2-title yellow-privacy">7. Combien de temps conservons-nous vos données ?</h2>
                <p className="yellow-privacy">
                  Nos activités tournant essentiellement autour des données à caractère personnel, la durée de conservation de celles-ci doit être assez longue afin d’en maintenir la meilleure qualité possible et d’ainsi réduire au mieux le risque d’erreurs. Ainsi, nous conservons vos données pour une durée de 15 ans à partir de leur dernier enregistrement dans notre base de données. Il est possible qu’une autre source nous communique également vos données, dans ce cas, le délai recommence à courir à partir de cette nouvelle communication.
                </p><p className="yellow-privacy">
                Ce délai relativement long s’explique également par le fait que nous effectuons de très nombreux traitements sur des données personnelles. Les entreprises pour le compte desquelles nous travaillons nous demandent de conserver la traçabilité de ces traitements.
              </p><p className="yellow-privacy">
                Comme nous vous l’avons déjà indiqué précédemment, il va de soi que si vous souhaitez soit que nous n’utilisions plus vos données à des fins publicitaires (droit d’opposition) soit être oublié, il vous suffit de suivre la procédure décrite ci-dessous. Dans ce cas, le délai de 15 ans n’est évidemment pas applicable et votre volonté primera.
              </p>


                {/* paragraphe seven ends */}

                {/* paragraphe eight starts */}
                <h2 className="h2-title yellow-privacy">8. Comment protégeons-nous vos données ?</h2>
                <p className="yellow-privacy">
                  Tout d’abord, nous vérifions scrupuleusement que nos partenaires commerciaux sources respectent le RGPD, que ce soit au niveau de la collecte de vos données ou au niveau de l’information qu’ils vous donnent à propos du traitement de vos données. En particulier, nous leur demandons d’être le plus transparent possible par rapport à la transmission des données à ENTER-BUSINESS et de renvoyer aux présentes pages web pour plus d’informations sur nos activités.
                </p><p className="yellow-privacy">
                Nous avons mis en place des procédures vous permettant d’exercer vos droits facilement. Vous n’êtes pas contre le fait de recevoir de la publicité mais vous préféreriez en recevoir par notre intermédiaire, uniquement via un medium spécifique (ex : uniquement à votre adresse postale mais pas par téléphone), pas de problème, il suffit de nous le signaler en ajustant vos choix sur votre compte sur le site ENTER-BUSINESS.COM.
              </p><p className="yellow-privacy">
                La sécurité de vos données est pour nous primordiale, nous y accordons beaucoup d’importance et nous faisons régulièrement des contrôles internes et externes pour nous assurer que les outils et procédures mis en place fonctionnent correctement.
              </p><p className="yellow-privacy">
                Nous avons désigné un « délégué à la protection des données » pour veiller au respect de vos droits.
              </p><p className="yellow-privacy">
                Enfin, dans le respect de la GDPR, nous avons réalisé une analyse d'impact relative à la protection des donnés. Ce document analyse tous les risques possibles d’atteinte à vos données et toutes les mesures à prendre pour éviter ou limiter un maximum ce risque. Les mesures préconisées ont été implémentées dans notre système IT et dans nos procédures.
              </p><p className="yellow-privacy">
                Nous mettons tout en œuvre pour assurer à vos données la plus grande confidentialité et la meilleure sécurité. Dans ce cadre, nous prenons toutes les mesures techniques et organisationnelles nécessaires.
              </p><p className="yellow-privacy">
                Ainsi notre réseau, notre infrastructure et nos systèmes d’information sont protégés. L’accès à vos données à caractère personnel n’est autorisé qu’aux seules personnes pour lesquelles cela s’avère nécessaire à l’accomplissement de leur mission. Elles sont tenues à une stricte discrétion professionnelle et doivent respecter toutes les prescriptions techniques prévues pour assurer la confidentialité des données à caractère personnel. Nous voulons ainsi éviter que des personnes non autorisées aient accès à vos données, les traitent, les modifient ou les détruisent.
              </p><p className="yellow-privacy">
                En cas de fuites de données, pouvant entraîner des risques d’atteinte à votre vie privée, vous êtes personnellement averti, tout comme la Commission pour la Protection de la Vie privée, conformément à nos obligations légales. Dans ce cas, nous mettrons tout en œuvre pour faire cesser le plus rapidement possible cet état des choses et pour en limiter toutes les conséquences.
              </p>

                <h4 className="h2-title yellow-privacy">ENTER-BUSINESS PEUT-ELLE TRANSMETTRE VOS DONNÉES EN DEHORS DE L’UNION EUROPÉENNE ?</h4>
                <p className="yellow-privacy">
                  Certains des clients ou fournisseurs d’ ENTER-BUSINESS peuvent transmettre vos données en dehors de l'Union Européenne. Dans ce cas, tous les traitements de vos données sont protégés conformément aux dispositions légales et nous veillons à ce que les traitements envisagés respectent un niveau de protection de vos données équivalent à la protection existant dans l’Union Européenne, notamment en signant les clauses contractuelles de la Commission Européenne.
                </p>

                {/* paragraphe eight ends */}



                {/* paragraphe nine starts */}
                <h2 className="h2-title yellow-privacy">9. Quels sont vos droits en matière de vie privée ?</h2>
                <h4 className="h2-title yellow-privacy">VOTRE DROIT DE CONSULTATION</h4>

                <p className="yellow-privacy">Vous avez le droit d’être informé à tout moment par ENTER-BUSINESS que nous traitons ou non vos données à caractère personnel et, si nous les traitons, de consulter ces informations et de recevoir des renseignements complémentaires à propos :</p>
                <ul className="yellow-privacy">

                  <li >des finalités du traitement ;</li>
                  <li>des catégories de données à caractère personnel concernées ;</li>
                  <li>des destinataires ou catégories de destinataires (notamment les destinataires établis dans des pays tiers) ;</li>
                  <li>si possible, du délai de conservation ou, en cas d’impossibilité, des critères pour déterminer ce délai ;</li>
                  <li>de l’existence de vos droits en matière de vie privée ;</li>
                  <li>du droit de déposer une plainte auprès de l’autorité de contrôle ;</li>
                  <li>des informations dont nous disposons sur la source des données si nous obtenons des données à caractère personnel via un tiers ; et</li>
                  <li>de l’existence du processus décisionnel automatisé.</li>
                </ul>

                <p className="yellow-privacy">Vous avez également le droit d’obtenir une copie gratuite des données traitées, dans un format compréhensible. ENTER-BUSINESS peut demander une indemnité raisonnable pour couvrir ses frais administratifs pour toute copie complémentaire que vous demandez.</p>

                <h4 className="h2-title yellow-privacy">VOTRE DROIT DE RECTIFICATION DES DONNÉES À CARACTÈRE PERSONNEL</h4>
                <p className="yellow-privacy">Vous avez le droit de faire rectifier les données à caractère personnel incomplètes, erronées, inadaptées ou obsolètes. Pour tenir vos données à jour, nous vous demandons quoi qu’il en soit de nous signaler toute modification, comme un déménagement ou une nouvelle fonction que vous exercez au sein de votre société. Pour ce faire vous pouvez vous connecter à votre compte sur <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                  .</p>


                <h4 className="h2-title yellow-privacy">VOTRE DROIT À L’EFFACEMENT DES DONNÉES (LE ‘DROIT À L’OUBLI’)</h4>
                <p className="yellow-privacy">
                  Vous avez le droit de faire supprimer vos données à caractère personnel sans retard déraisonnable. Pour ce faire vous pouvez vous connecter à votre compte sur <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                  .
                </p>

                <h4 className="h2-title yellow-privacy">VOTRE DROIT À LA LIMITATION DU TRAITEMENT</h4>
                <p className="yellow-privacy">
                  Vous avez le droit d’obtenir la limitation du traitement de vos données à caractère personnel et notamment dans le cadre de l’utilisation de vos données à des fins de marketing direct.</p>
                <p className="yellow-privacy">
                  Pour ce faire vous pouvez vous connecter à votre compte sur <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                  .
                </p>


                <h4 className="h2-title yellow-privacy">VOTRE DROIT À LA PORTABILITÉ DE VOS DONNÉES À CARACTÈRE PERSONNEL (‘DATA PORTABILITY’)</h4>

                <p className="yellow-privacy">
                  Vous avez le droit de ‘récupérer’ vos données à caractère personnel.</p>
                <p className="yellow-privacy"><span className="yellow-privacy">
Deux aspects sont liés à ce droit :</span></p>
                <p className="yellow-privacy">À partir de votre compte sur <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                </p>
                <p className="yellow-privacy">1.	vous pouvez demander à ENTER-BUSINESS de récupérer les données à caractère personnel concernées.</p>
                <p className="yellow-privacy">2.	Vous pouvez demander à ENTER-BUSINESS de transmettre directement les données à caractère personnel concernées à un autre responsable du traitement. Vous êtes en outre personnellement responsable de l’exactitude et de la sécurité de l’adresse e-mail que vous indiquez en vue de la transmission. ENTER-BUSINESS a le droit de refuser si la transmission est techniquement impossible
                </p>


                <h4 className="h2-title yellow-privacy">VOTRE DROIT DE VOUS OPPOSER AU TRAITEMENT DE VOS DONNÉES À CARACTÈRE PERSONNEL</h4>
                <p className="yellow-privacy">Vous avez le droit de vous opposer, sur la base de votre situation particulière, au traitement de vos données à caractère personnel. ENTER-BUSINESS cessera le traitement de vos données à caractère personnel, sauf si ENTER-BUSINESS peut prouver des motifs contraignants et légitimes au traitement qui sont plus importants que les vôtres ou lorsque le traitement des données à caractère personnel concerne la constatation, l’exercice ou la défense de droits en justice (par exemple, l’introduction d’une requête auprès d’un tribunal).</p>
                {/* paragraphe nine ends */}

                {/* paragraphe ten starts */}
                <h2 className="h2-title yellow-privacy">10. Comment pratiquement exercer vos droits en matière de vie privée ?</h2>
                <p className="yellow-privacy">Via le site <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                  .</p>
                <p className="yellow-privacy">Cette intervention entraîne-t-elle des frais ? Vous pouvez exercer gratuitement vos droits en matière de vie privée, sauf si votre demande est manifestement non fondée ou exagérée, notamment en raison de son caractère répétitif. Dans ce cas, conformément à la législation en matière de vie privée, nous avons le droit et le choix de (i) facturer une indemnité raisonnable (compte tenu des frais administratifs pour fournir les informations ou communications demandées et des frais qui accompagnent la prise des mesures demandées) ou (ii) refuser de donner suite à la demande.
                </p>
                <p className="yellow-privacy">Sous quelle forme recevez-vous une réponse ? Lorsque vous introduisez une demande par voie électronique, les informations sont fournies par voie électronique si possible, sauf si vous demandez une communication par une autre voie. Dans tous les cas, nous vous fournissons une réponse succincte, transparente, compréhensible et aisément accessible.
                </p>
                <p className="yellow-privacy">Quand vais-je recevoir une réponse ? Nous réagissons au plus vite à votre demande et, en tout cas, dans le mois de la réception de votre demande. En fonction de la complexité des demandes et de leur nombre, ce délai peut au besoin être prolongé de deux mois. En cas de prolongation du délai, nous vous en informons dans le mois de la réception de la demande.
                </p>
                <p className="yellow-privacy">Que faire si ENTER-BUSINESS ne donne pas suite à ma demande ? Nous vous informons à chaque fois dans notre réponse de la possibilité de déposer une plainte auprès de l'autorité de contrôle et d'introduire un recours en justice.
                </p>

                {/* paragraphe ten ends */}

                {/* paragraphe eleven starts */}
                <h2 className="h2-title yellow-privacy">  11. Comment contacter ENTER-BUSINESS ?</h2>
                <p className="yellow-privacy">
                  Si vous voulez joindre ENTER-BUSINESS en rapport avec la présente politique, vous pouvez le faire entre autres par écrit au siège d’exploitation de la société : IN TEMPORE srl – Avenue reine Astrid 92 à 1310 La Hulpe ou par email à cette adresse :privacy@intempore.biz.
                </p><p className="yellow-privacy">
                Pour toutes les informations à propos de la présente politique ou les plaintes en rapport avec le traitement de vos données à caractère personnel, vous pouvez contacter le Data Protection Officer de ENTER-BUSINESS via privacy@intempore.biz.
              </p>

                {/* paragraphe eleven ends */}


                {/* paragraphe twelve starts */}
                <h2 className="h2-title yellow-privacy">12. Soyez informé des adaptations de ce texte</h2>
                <p className="yellow-privacy">
                  ENTER-BUSINESS peut modifier la présente politique de temps à autre, par exemple à la suite d’évolutions du marché et de nouvelles activités de traitement de ENTER-BUSINESS. Nous vous invitons dès lors à toujours consulter la dernière version de la présente politique sur notre site Internet.
                </p><p className="yellow-privacy">
                Naturellement, nous vous informons au préalable, via nos sites Internet ou autres canaux de communication courants, de tout changement de contenu et nous vous demandons votre consentement préalable pour nos (nouvelles) activités de traitement, lorsque la loi l'exige.
              </p>

                {/* paragraphe twelve ends */}



                {/* paragraphe thirteen starts */}
                <h2 className="h2-title yellow-privacy">13. Communication à l'autorité de contrôle</h2>
                <p className="yellow-privacy">
                  Vous pouvez adresser les plaintes en rapport avec le traitement de vos données à caractère personnel par ENTER-BUSINESS à l'Autorité de protection des données (APD) , rue de la Presse 35, 1000 Bruxelles / +32 (0)2 274 48 00 / https://autoriteprotectiondonnees.be/citoyen
                </p>

                {/* paragraphe thirteen ends */}


              </Col>
              {/*<Col lg="2" className="text-center mt-5">
                <a href={'/feed'} className="btn BackBtn">
                  <img
                    className="img-fluid"
                    src={backBtn}
                    alt="Enter Business"
                  />
                  <span>Back</span>
                </a>
              </Col>*/}
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  )
}


export default PrivacyTerms